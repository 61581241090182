import axios from "axios";
import { useState, useEffect } from "react";
import { BaseUrl } from '../config';
import OwlCarousel from 'react-owl-carousel';
import parse from 'html-react-parser';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const owlReponsive = {
    0: {
        items: 1,
    },
    480: {
        items: 2,
    },
    768: {
        items: 3
    },
    900: {
        items: 4
    }
}
const owlReponsive2 = {
    0: {
        items: 1,
    },
    480: {
        items: 1,
    },
    768: {
        items: 2
    },
    900: {
        items: 3
    }
}
export default function About() {
    const [abouts, setAbouts] = useState([]);
    const [partners, setParners] = useState([]);
    const [loadingAbouts, setLoadingAbouts] = useState(true);
    const [loadingPartners, setLoadingPartners] = useState(true);



    const gettAbouts = () => {
        axios
            .get(`${BaseUrl}/events`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data.length > 0)
                        setAbouts(data);
                }
            })
            .catch(err => console.error(err.message))
            .finally(() => setLoadingAbouts(false));
    }

    const getPartners = () => {
        axios
            .get(BaseUrl + '/partners')
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data.length > 0)
                        setParners(data);
                }
            })
            .catch(err => console.error(err.message))
            .finally(() => setLoadingPartners(false));
    }

    useEffect(() => {
        gettAbouts();
        getPartners();
    }, []);

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-min.jpg')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">A propos</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Maison de l'Innovation Sociale Numérique</p>
                            {/* <p data-aos="fade-up" data-aos-delay="200"><a href="#" className="btn btn-primary">Get Started</a></p> */}
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/reactAssets/images/slant.svg')` }}></div>
            </div>
            <div className="site-section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <h4 className="font-weight-bold">Nos Promoteurs</h4>
                        </div>
                    </div>

                    {partners?.length > 0 &&
                        <div className="col-12 row align-items-center">
                            {
                                partners.map((partner, index) => (
                                    <div key={"promteur#" + index} className="item col-12 col-md-6 col-lg-4">
                                        <div className="w-100 d-flex align-items-center" style={{ minHeight: 150 }}>
                                            {partner.id === 5 ?
                                                <img src={partner.logo} alt={`Logo de ${partner.name}`} title={partner.name} className="img-fluid mx-auto" style={{ height: 150, width: '70%' }} />
                                                : <img src={partner.logo} alt={`Logo de ${partner.name}`} title={partner.name} className="img-fluid mx-auto" style={{ maxWidth: 100 }} />
                                            }
                                        </div>
                                        <div className="containe">
                                            <h6 className="text-center">{partner.name}</h6>
                                            <p className="text-showmore small text-justify">
                                                {partner.short}
                                            </p>
                                            <p><a href={"javascript:;"} data-toggle="modal" data-target={`#partner-${index}`}>lire plus</a></p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {loadingPartners &&
                        <div className="w-100 text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {/* Partners modals to show more about them */}
                    {partners?.length > 0 && partners.map((item, index) => (
                        <div key={`partner-${index}`} className="modal fade" id={`partner-${index}`} tabIndex="-1" aria-labelledby={`#partner-${index}Label`} aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={`#partner-${index}Label`}>{`Info : ${item.name}`}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="text-center">
                                            <img src={item.logo} alt={`Logo de ${item.name}`} title={item.name} className="img-fluid" width={'50%'} />
                                        </p>
                                        <div>{item.description ? parse(item.description ?? '') : item.short}</div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                        <a target={'_blank'} href={item.link} className="btn btn-primary">aller sur le site</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="site-section slider-team-wrap bg-light">
                <div className="container">

                    <div className="row mb-5 align-items-center">
                        <div className="col-md-6">
                            <h2 className="font-weight-bold text-capitalize">nos évènements</h2>
                        </div>
                        <div className="col-md-6 text-left text-md-right">
                            <div className="slider-nav">
                                <a href="#" className="js-prev js-custom-prev-v2"><span className="icon-arrow_back"></span></a>
                                <a href="#" className="js-next js-custom-next-v2"><span className="icon-arrow_forward"></span></a>

                            </div>
                        </div>
                    </div>

                    {abouts.length > 0 &&
                        <OwlCarousel nav={true} navElement={['<a href="#" className="js-prev js-custom-prev-v2"><span className="icon-arrow_back"></span></a>', '<a href="#" className="js-next js-custom-next-v2"><span className="icon-arrow_forward"></span></a>']} responsive={owlReponsive2} className="slider-team owl-carousel owl-theme no-nav owl-3-slider" loop autoPlay autoplayTimeout={3000}>
                            {
                                abouts.map((item, index) => (
                                    <div key={`Abouthere#${index}`} className="team mx-2">
                                        <div className="pic" style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', height: 350 }}>
                                        </div>
                                        <h3 className="">
                                            <a href="#"><span className="">{item.title}</span></a>
                                        </h3>
                                        <div className='about-detail'>
                                            <div>
                                                {parse(item.short)}
                                            </div>
                                        </div>
                                        <p className="mb-0"><a href="#" className="more dark" data-toggle="modal" data-target={`#about-${index}`}>Lire plus <span className="icon-arrow_forward"></span></a></p>
                                    </div>
                                ))
                            }

                        </OwlCarousel>
                    }
                    {loadingAbouts &&
                        <div className="w-100 text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {/* Abouts modals to show more about them */}
                    {abouts?.length > 0 && abouts.map((item, index) => (
                        <div key={`about-${index}`} className="modal fade" id={`about-${index}`} tabIndex="-1" aria-labelledby={`#about-${index}Label`} aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={`#about-${index}Label`}>{`Info : ${item.title}`}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={item.image} alt={`Logo de ${item.title}`} title={item.title} className="img-fluid" width={'100%'} />
                                        <div>{parse(item.description)}</div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                        {/* <a target={'_blank'} href={item.link} className="btn btn-primary">Lire plus</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </>
    );
}