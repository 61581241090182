import { useState, useEffect } from "react";
import Axios from "axios";
import { BaseUrl } from "../../config";
import swal from "sweetalert";
import { DotLoader } from "react-spinners";

export default function Register() {
    const [isSubmitting, setIsSubmetting] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [tel, setTel] = useState("");
    const [gender, setGender] = useState("h");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    function resetFields() {
        setShowPass(false);
        setName('');
        setEmail('');
        setAge('');
        setTel('');
        setGender('');
        setPassword('');
        setPasswordConfirmation('');
    }

    function handleRegister() {
        setIsSubmetting(true)
        let body = { name: name, email: email, age: age, tel: tel, gender: gender, password: password, password_confirmation: passwordConfirmation };
        Axios.post(`${BaseUrl}/register`, body, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    localStorage.setItem('user-data', JSON.stringify(res.data));
                    swal('Succès', "Votre compte a été créer avec succès!", "success")
                        .then(() => {
                            resetFields();
                            window.location.pathname = '/'
                        })
                }
            })
            .catch(err => {
                if (err.response?.data) {
                    const { data } = err.response;
                    if (data.errors)
                        setErrors(data.errors);
                }
            })
            .finally(() => setIsSubmetting(false))
    }


    useEffect(() => { }, [errors])

    return (
        <>
            <div className="site-section bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="d-none d-md-block col-md-4 bg-primary py-3 px-5 rounded">
                            <p className="text-center py-4">
                                <img src="/logo.png" alt="logo MISN" className="img-fluid" />
                            </p>

                        </div>
                        <div className="col-12 col-md-7 mx-auto border rounded mx-2 py-3">
                            <h5 className="text-center text-uppercase">Création de compte</h5>
                            <form className="needs-validation w-100 mx-auto">
                                <div className="form-row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="name">{'Nom & Prénom'}</label>
                                        <input type="text" className={`form-control ${errors?.name ? 'is-invalid' : ''}`} id="name" placeholder="Nom complet" value={name} onChange={e => setName(e.target.value)} required />
                                        {errors.name &&
                                            <div className="invalid-feedback">
                                                {errors.name[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="age">{'Âge'}</label>
                                        <input type="number" min={18} max={75} className={`form-control ${errors?.age ? 'is-invalid' : ''}`} id="age" value={age} onChange={e => setAge(e.target.value)} required />
                                        {errors.age &&
                                            <div className="invalid-feedback">
                                                {errors.age[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="">Genre</label>
                                    </div>
                                    <div className="col px-3 form-check form-check-inline">
                                        <input className={`form-check-input ${errors?.gender ? 'is-invalid' : ''}`} type="radio" name="gender" id="gender1" onChange={() => setGender('f')} />
                                        <label className="form-check-label" htmlFor="gender1">Femme</label>
                                    </div>
                                    <div className="col px-3 form-check form-check-inline">
                                        <input className={`form-check-input ${errors?.gender ? 'is-invalid' : ''}`} checked type="radio" name="gender" id="gender2" onChange={() => setGender('h')} />
                                        <label className="form-check-label" htmlFor="gender2">Homme</label>
                                    </div>
                                    {errors.gender &&
                                        <div className="invalid-feedback">
                                            {errors.gender[0]}
                                        </div>
                                    }
                                </div>
                                <div className="form-row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="email">{'Email'}</label>
                                        <input type="email" className={`form-control ${errors?.email ? 'is-invalid' : ''}`} id="email" placeholder="Votre email" value={email} onChange={e => setEmail(e.target.value)} required />
                                        {errors.email &&
                                            <div className="invalid-feedback">
                                                {errors.email[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="tel">{'Tel'}</label>
                                        <input type="text" className={`form-control ${errors?.tel ? 'is-invalid' : ''}`} placeholder="+235 66 XX XX XX" id="tel" value={tel} onChange={e => setTel(e.target.value)} />
                                        {errors.tel &&
                                            <div className="invalid-feedback">
                                                {errors.tel[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row align-item-center">
                                    <div className="col-md mb-3">
                                        <label htmlFor="password">Mot de passe</label>
                                        <input type={!showPass ? "password" : "text"} className={`form-control ${errors?.password ? 'is-invalid' : ''}`} id="password" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                                        <button type="button" className="btn password-btn" onClick={() => setShowPass(!showPass)}>
                                            {showPass ?
                                                <img src="/reactAssets/images/eye-slash.png" alt="" />
                                                :
                                                <img src="/reactAssets/images/eye-icon.png" alt="" />
                                            }
                                        </button>
                                        {errors.password &&
                                            <div className="invalid-feedback">
                                                {errors.password[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="password_confirmation">Confirmation</label>
                                        <input type={!showPass ? "password" : "text"} className={`form-control ${errors?.password_confirmation ? 'is-invalid' : ''}`} id="password_confirmation" placeholder="Confirmation" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} required />
                                        <button type="button" className="btn password-btn" onClick={() => setShowPass(!showPass)}>
                                            {showPass ?
                                                <img src="/reactAssets/images/eye-slash.png" alt="" />
                                                :
                                                <img src="/reactAssets/images/eye-icon.png" alt="" />
                                            }
                                        </button>
                                        {errors.password_confirmation &&
                                            <div className="invalid-feedback">
                                                {errors.password_confirmation[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <p className="text-white">
                                    <a href="#">mot de passe oublié ?</a>
                                </p>
                                <button disabled={isSubmitting} className="btn btn-primary" type="button" onClick={() => handleRegister()}>
                                    S'incrire
                                    <DotLoader
                                        color={'#fff'}
                                        loading={isSubmitting}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}