import Axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BaseUrl, slug } from "../config";

export default function Projects() {
    const { type = null } = useParams();
    const [innovations, setInnovations] = useState([]);
    const [loadingInnovations, setLoadingInnovations] = useState(true);

    useEffect(() => {
        getInnovation();
    }, [])

    async function getInnovation() {
        let url = 1;
        if (type !== null) {
            if (type.search('marche') === 0)
                url = 2;
            if (type.search('mis-a-echelle') === 0)
                url = 3;
        }
        console.log(type, url);
        Axios.
            get(`${BaseUrl}/statuses/${url}/projects/`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setInnovations(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
            .finally(() => setLoadingInnovations(false))
    }

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-min.png')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">
                                {
                                    type && (type.search('ideation') === 0 && 'Innovations en Idéations' || (type.search('marche') === 0 && 'Innovations en marche') || (type.search('mis-a-echelle') === 0 && 'Innovations mis à l\'Echelle.'))
                                }
                                {type === null && "Innovations en Idéations"}
                            </h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Maison de l'Innovation Sociale Numérique</p>
                            {/* <p data-aos="fade-up" data-aos-delay="200"><a href="#" className="btn btn-primary">Get Started</a></p> */}
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/assets/images/slant.svg')` }}></div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">Innovations</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            innovations?.length > 0 && innovations?.map((item, i) => (
                                <div key={`innovation-${i}`} className="col-md-6 col-lg-4 mb-4 mb-lg-4 mx-auto" data-aos="fade-up">
                                    <div className="unit-4">
                                        <div className="unit-4-icon mr-4" style={{ backgroundImage: `url(${item.image ? item.image : '/assets/images/hero-min.jpg'})`, backgroundSize: 'cover', height: 220, width: "100%" }}>
                                            {/* <span className="feather-pen-tool"></span> */}
                                            {/* <img src={item.imgurl} alt="" className="img-fluid" /> */}
                                        </div>
                                        <div className="bg-white p-3">
                                            <h5 className="title-showmore">{item.title}</h5>
                                            <p> <b> Domaine :</b> {item.domaine}</p>
                                            <div className=''>
                                                <div className="text-showmore">
                                                    {item.short}
                                                </div>
                                            </div>
                                            <p><a href={`/innovations/${item.id}/${slug(item.title)}`} >Lire plus</a></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {!loadingInnovations && innovations?.length === 0 &&
                            <p className="text-center w-100">Aucun élément pour le moment.</p>
                        }
                        {loadingInnovations &&
                            <div className="w-100 text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    );

}