import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './pages/About';
import AddProject from './pages/auth/AddProject';
import Login from './pages/auth/Login';
import Profile from './pages/auth/Profile';
import Register from './pages/auth/Register';
import Blog from './pages/Blog';
import Coffee from './pages/Coffee';
import Contact from './pages/Contact';
import Formations from './pages/Formations';
import Gouverning from './pages/Gouverning';
import Index from './pages/index';
import Projects from './pages/Projects';
import ShowFormation from './pages/ShowFormation';
import ShowPost from './pages/ShowPost';
import ShowProject from './pages/ShowProject';
import { substr, lastIndexOf } from '@7urtle/lambda';
import { createRoot } from 'react-dom';
import Aide from './pages/Aide';

export default function Root() {

    const getBaseName = (path) => substr(lastIndexOf('/')(path))(0)(path);
    // basename={getBaseName(window.location.pathname)}
    return (
        <BrowserRouter >
            <Routes>
                <Route path='/' element={<Navbar />}>
                    <Route index path='' element={<Index />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='a-propos/' >
                        <Route index path='' element={<About />} />
                        <Route path='gouvernance' element={<Gouverning />} />
                        <Route path='aide' element={<Aide />} />
                    </Route>
                    <Route path='contact' element={<Contact />} />
                    <Route path='login' element={<Login />} />
                    <Route path='register' element={<Register />} />
                    <Route path='formations/' >
                        <Route index path='' element={<Formations />} />
                        <Route path=':slug' element={<ShowFormation />} />
                    </Route>
                    <Route path='/coffees' element={<Coffee />} />
                    <Route path='/coffees/:slug' element={<ShowFormation />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/:slug' element={<ShowPost />} />
                    <Route path='/innovations' element={<Projects />} />
                    <Route path='/innovations/:type' element={<Projects />} />
                    <Route path='/innovations/:id/:slug' element={<ShowProject />} />
                    <Route path='/innovations/ajouter' element={<AddProject />} />
                    <Route path='/innovations/:id/edit' element={<AddProject />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);