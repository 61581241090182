import { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { BaseUrl, getInitials, slug } from "../../config";

export default function Profile() {
    let userData = {};

    if (localStorage.getItem('user-data').length > 0) {
        userData = JSON.parse(localStorage.getItem('user-data'));
    }

    if (!userData.token || !userData.user)
        swal('Alert', 'Votre session n\êtes plus valide, veillez vous reconnecter', 'warning')
            .then(() => {
                window.location = '/'
            });

    const { user = {}, token } = userData;

    const [isSubmitting, setIsSubmetting] = useState(false);
    const [showPassOld, setShowPassOld] = useState(false);
    const [deleting, setDeleting] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [showPassConf, setShowPassConf] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState(user?.email ?? "");
    const [name, setName] = useState(user?.name ?? "");
    const [age, setAge] = useState(user?.age ?? "");
    const [tel, setTel] = useState(user?.tel ?? "");
    const [gender, setGender] = useState(user?.gender ?? "h");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [projects, setProjects] = useState([]);

    const handleUpdate = () => {
        setIsSubmetting(true);
        const data = { name: name, email: email, tel: tel, age: age, gender: gender, _method: 'PUT' };
        axios.
            post(`${BaseUrl}/users/${user.id}`, data, {
                headers: {
                    'Authorization': `Bearer ${token ?? ""}`
                }
            })
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    const credentials = { ...userData, user: data };
                    localStorage.setItem('user-data', JSON.stringify(credentials));
                    swal('Mis à jour', "Informations mis à jours avec succès!", "success");
                }
            })
            .catch(err => {
                if (err.response?.data) {
                    const { data } = err.response;
                    if (data.errors)
                        setErrors(data.errors);
                }
                else
                    swal('Oups !', "Votre profile n'a pas été mis à jour, veillez réessayer!", "danger");

            })
            .finally(() => {
                setIsSubmetting(false);
            })
    }

    async function removeProject(id) {
        setDeleting(id);
        swal({
            title: "Êtes vous sûr ?",
            text: "Après suppression, votre projet dispparaîtra de la plateforme!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.
                        post(`${BaseUrl}/projects/${id}`, { _method: 'DELETE' }, {
                            headers: {
                                'Authorization': `Bearer ${token ?? ""}`
                            }
                        })
                        .then(res => {
                            swal('Suppression', "Supprimer avec succès", "success")
                                .then(res => {
                                    let newlist = projects.filter(item => item.id !== id);
                                    setProjects(newlist);
                                });
                        })
                        .catch(err => {
                            if (err.response?.data) {
                                const { data } = err.response;
                                if (data.errors)
                                    setErrors(data.errors);
                            }
                            else
                                swal('Oups !', "Impossible de supprimer, veillez réessayer!", "danger");
                        })
                        .finally(() => {
                            setDeleting(null);
                        })
                }
            });


    }

    async function getProjects() {
        axios.
            get(`${BaseUrl}/projects?user=${user.id}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setProjects(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
    }
    useEffect(() => {
        getProjects();
    }, [])

    return (
        <div class="container bootstrap snippet">
            <div class="row">
                <div class="col-sm-10 text-center"><h1>{user.name}</h1></div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="text-center">
                        <h2>
                            <a href="#" className="text-uppercase rounded-circle px-5 py-5 bg-secondary text-white">{getInitials(userData.user?.name)}</a>
                        </h2>
                    </div>
                    <hr /><br />
                    <ul class="list-group">
                        <li class="list-group-item text-muted">{"Projets"} <i class="fa fa-dashboard fa-1x"></i></li>
                        <li class="list-group-item text-right"><span class="pull-left"><strong>{"Idéations"}</strong></span> {projects?.filter(item => item.status_id === 1)?.length ?? 0}</li>
                        <li class="list-group-item text-right"><span class="pull-left"><strong>{"En marche"}</strong></span> {projects?.filter(item => item.status_id === 2)?.length ?? 0}</li>
                        <li class="list-group-item text-right"><span class="pull-left"><strong>{"Mise à l'échelle"}</strong></span> {projects?.filter(item => item.status_id === 3)?.length ?? 0}</li>
                    </ul>
                    {/* <div class="panel panel-default">
                        <div class="panel-heading">Social Media</div>
                        <div class="panel-body">
                            <i class="fa fa-facebook fa-2x"></i>
                            <i class="fa fa-github fa-2x"></i>
                            <i class="fa fa-twitter fa-2x"></i>
                            <i class="fa fa-pinterest fa-2x"></i>
                            <i class="fa fa-google-plus fa-2x"></i>
                        </div>
                    </div> */}

                </div>
                <div class="col-sm-9">
                    <ul class="nav nav-tabs">
                        <li class="active"><a className="mx-2" data-toggle="tab" href="#home">{'Compte'}</a></li>
                        <li><a className="mx-2" data-toggle="tab" href="#password">{'Mot de passe'}</a></li>
                        <li><a className="mx-2" data-toggle="tab" href="#settings">{'Projets'}</a></li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="home">
                            <hr />
                            <form class="form" action="##" method="post" id="registrationForm">
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label for="name"><h4>Nom Complet</h4></label>
                                        <input type="text" class="form-control" name="name" id="name" value={name} onChange={e => setName(e.target.value)} placeholder="Nom" title="saissir votre nom..." />
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor=""><h4>Genre</h4></label>
                                    </div>
                                    <div className="col px-3 form-check form-check-inline">
                                        <input className={`form-check-input ${errors?.gender ? 'is-invalid' : ''}`} checked={gender === "f"} type="radio" name="gender" id="gender1" onChange={() => setGender('f')} />
                                        <label className="form-check-label" htmlFor="gender1"><h6>Femme</h6></label>
                                    </div>
                                    <div className="col px-3 form-check form-check-inline">
                                        <input className={`form-check-input ${errors?.gender ? 'is-invalid' : ''}`} checked={gender === "h"} type="radio" name="gender" id="gender2" onChange={() => setGender('h')} />
                                        <label className="form-check-label" htmlFor="gender2"><h6>Homme</h6></label>
                                    </div>
                                    {errors.gender &&
                                        <div className="invalid-feedback">
                                            {errors.gender[0]}
                                        </div>
                                    }
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label for="email"><h4>Email</h4></label>
                                        <input type="text" class="form-control" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="example@misn-td.org" title="saissir votre email" />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label for="tel"><h4>Tel</h4></label>
                                        <input type="text" class="form-control" name="tel" id="tel" value={tel} onChange={e => setTel(e.target.value)} placeholder="Téléphone" title="saissir votre tel" />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label for="age"><h4>Âge</h4></label>
                                        <input type="number" min={18} max={80} class="form-control" name="age" id="age" value={age} onChange={e => setAge(e.target.value)} placeholder="Votre Âge" title="saissir votre âge..." />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <br />
                                        <button disabled={isSubmitting} class="btn btn-lg btn-success pull-right" type="button" onClick={handleUpdate}><i class="glyphicon glyphicon-ok-sign"></i> Enregistrer</button>
                                        <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Annuler</button>
                                    </div>
                                </div>
                            </form>

                            <hr />

                        </div>
                        <div class="tab-pane" id="password">
                            <h2></h2>
                            <hr />
                            <form class="form" action="##" method="post" id="registrationForm">
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label htmlFor="oldPassword">Ancien Mot de passe</label>
                                        <div className="d-flex">
                                            <input type={!showPassOld ? "password" : "text"} className={`form-control ${errors?.oldPassword ? 'is-invalid' : ''}`} id="oldPassword" placeholder="Ancien Mot de passe" value={oldPassword} onChange={e => setOldPassword(e.target.value)} required />
                                            <button type="button" className="btn password-btn-profile" onClick={() => setShowPassOld(!showPassOld)}>
                                                {showPassOld ?
                                                    <img src="/reactAssets/images/eye-slash.png" alt="" />
                                                    :
                                                    <img src="/reactAssets/images/eye-icon.png" alt="" />
                                                }
                                            </button>
                                        </div>
                                        {errors.oldPassword &&
                                            <div className="invalid-feedback">
                                                {errors.old_password[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label htmlFor="password">Mot de passe</label>
                                        <div className="d-flex">
                                            <input type={!showPass ? "password" : "text"} className={`form-control ${errors?.password ? 'is-invalid' : ''}`} id="password" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                                            <button type="button" className="btn password-btn-profile" onClick={() => setShowPass(!showPass)}>
                                                {showPass ?
                                                    <img src="/reactAssets/images/eye-slash.png" alt="" />
                                                    :
                                                    <img src="/reactAssets/images/eye-icon.png" alt="" />
                                                }
                                            </button>
                                        </div>
                                        {errors.password &&
                                            <div className="invalid-feedback">
                                                {errors.password[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <label htmlFor="password_confirmation">Confirmation</label>
                                        <div className="d-flex">
                                            <input type={!showPassConf ? "password" : "text"} className={`form-control ${errors?.password_confirmation ? 'is-invalid' : ''}`} id="password_confirmation" placeholder="Confirmation" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} required />
                                            <button type="button" className="btn password-btn-profile" onClick={() => setShowPassConf(!showPassConf)}>
                                                {showPassConf ?
                                                    <img src="/reactAssets/images/eye-slash.png" alt="" />
                                                    :
                                                    <img src="/reactAssets/images/eye-icon.png" alt="" />
                                                }
                                            </button>
                                        </div>
                                        {errors.password_confirmation &&
                                            <div className="invalid-feedback">
                                                {errors.password_confirmation[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-12">
                                        <br />
                                        <button class="btn btn-lg btn-success pull-right" type="button"><i class="glyphicon glyphicon-ok-sign"></i> Enregistrer</button>
                                        <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Annuler</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane" id="settings">
                            <hr />
                            <div className="d-flex align-items-center my-1">
                                <h5 className="flex-grow-1">Liste des projets</h5>
                                <a href="/innovations/ajouter" className="btn btn-primary">Ajouter</a>
                            </div>
                            <table class="table">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Titre</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Réalisation</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.length > 0 &&
                                        projects.map((item, index) => (
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.title}</td>
                                                <td>{item.status?.name}</td>
                                                <td>{item.realisation} %</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <a href={`/innovations/${item.id}/edit`} className="mx-1 btn btn-primary" target="_blank" rel="noopener noreferrer">
                                                            <img src="/reactAssets/images/icons/edit.png" width={20} alt="" />
                                                        </a>
                                                        <a href={`/innovations/${item.id}/${slug(item.title)}`} className="mx-1 btn btn-info" rel="noopener noreferrer">
                                                            <img src="/reactAssets/images/icons/eye.png" width={20} alt="" />
                                                        </a>
                                                        <buttton onClick={() => removeProject(item.id)} disabled={deleting && deleting === item.id} className="mx-1 btn btn-danger" rel="noopener noreferrer">
                                                            <img src="/reactAssets/images/icons/trash.png" width={20} alt="" />
                                                        </buttton>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );

}