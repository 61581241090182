import Axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseUrl, slug } from "../config";

export default function Blog() {
    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(true);

    useEffect(() => {
        getDatas();
    }, [])

    async function getDatas() {
        let url = "/posts";
        Axios.
            get(`${BaseUrl}${url}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setPosts(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
            .finally(() => setLoadingPosts(false))
    }

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-2.png')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Blog - Articles</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Les articles sur l'Actualités et l'Innovation</p>
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/reactAssets/images/slant-primary.svg')` }}></div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">Les Articles</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            posts?.length > 0 && posts?.map((item, i) => (
                                <div key={`formation-${i}`} className="col-md-6 col-lg-4 mb-4 mb-lg-4 mx-auto" data-aos="fade-up">
                                    <div className="unit-4">
                                        <div className="unit-4-icon mr-4" style={{ backgroundImage: `url(${(new URL(item.image)).href})`, backgroundSize: 'cover', height: 220, width: "100%" }}>
                                        </div>
                                        <div className="bg-white p-3">
                                            <span className="ml-auto">{item.published_date}</span>
                                            <h5 className="title-showmore">{item.title}</h5>
                                            <div className=''>
                                                <p className="text-showmore">
                                                    {item.short}
                                                </p>
                                            </div>
                                            <p><Link to={`/blog/${item.id}-${slug(item.title)}`} data-toggle="modal" data-target={`#about-${i}`}>Lire plus</Link></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {!loadingPosts && posts?.length === 0 &&
                            <p className="text-center w-100">Aucun élément pour le moment.</p>
                        }
                        {loadingPosts &&
                            <div className="w-100 text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    );

}