import Axios from "axios";
import react, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseUrl, slug } from "../config";
import parse from 'html-react-parser';

export default function Coffee() {
    const [coffees, setCoffees] = useState([]);
    const [coffeVideos, setCoffeVideos] = useState([]);
    const coffeeslinks = [
        '<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F1400046123749932%2F&show_text=true&width=476&t=0" width="100%" height="625" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
        '<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F1269192453604564%2F&show_text=true&width=476&t=0" width="100%" height="625" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
        '<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F1368574233661213%2F&show_text=true&width=476&t=0" width="100%" height="625" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
        '<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F495356741912081%2F&show_text=true&width=560&t=0" width="100%" height="625" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
        '<iframe src="https://www.facebook.com/plugins/video.php?height=500&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F721749742066156%2F&show_text=true&width=560&t=0" width="100%" height="625" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
        '<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F1146622239085777%2F&show_text=true&width=560&t=0" width="560" height="429" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>'
    ];

    useEffect(() => {
        getDatas();
    }, [])

    async function getDatas() {
        let url = "/coffes";
        Axios.
            get(`${BaseUrl}${url}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data && data.length > 0) {
                        setCoffees(data);
                        // const validVs = data.filter(cof => cof.video !== null) ?? [];
                        // const validTs = data.filter(cof => cof.video === null) ?? [];
                        // if (validVs.length > 0) {
                        //     setCoffeVideos(validVs);
                        // }
                        // if (validTs.length > 0) {
                        //     setCoffees(validTs);
                        // }
                    }
                }
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/misn-presentation.png')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Cafés de l'innovation</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Maison de l'Innovation Sociale Numérique</p>
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/reactAssets/images/slant-primary.svg')` }}></div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">Les cafés de l'Innovation</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            coffees?.length > 0 && coffees?.map((item, i) => (
                                <div key={`coffee-${i}`} className="col-md-12 col-lg-6 mb-4 mb-lg-4 mx-auto" data-aos="fade-up">
                                    <div className="unit-4">
                                        {item.video === null &&
                                            <div className="unit-4-icon mr-4" style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', height: 220, width: "100%" }}>
                                            </div>
                                        }
                                        {item.video !== null &&
                                            <div className="unit-4-icon mr-4">
                                                {parse(item.video)}
                                            </div>
                                        }
                                        <div className="bg-white p-3">
                                            <h5 className="title-showmore">{item.title}</h5>
                                            <div className=''>
                                                <p className="text-showmore">
                                                    {item.short}
                                                </p>
                                            </div>
                                            {item.video === null && <p><Link to={`/coffees/${item.id}-${slug(item.title)}`} data-toggle="modal" data-target={`#about-${i}`}>Lire plus</Link></p>}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </>
    );

}