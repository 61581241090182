import { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { BaseUrl } from "../../config";
import { useParams } from "react-router-dom";

export default function AddProject() {
    let userData = {};
    let { id = null } = useParams();
    let [project, setProject] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());

    if (localStorage.getItem('user-data').length > 0) {
        userData = JSON.parse(localStorage.getItem('user-data'));
    }

    if (!userData.token || !userData.user)
        swal('Alert', 'Votre session n\êtes plus valide, veillez vous reconnecter', 'warning')
            .then(() => {
                window.location = '/'
            });

    const { user = {}, token } = userData;

    const [isSubmitting, setIsSubmetting] = useState(false);
    const [errors, setErrors] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState("");
    const [domaine, setDomaine] = useState("");
    const [realisation, setRealisation] = useState("");
    const [motivation, setMotivation] = useState("");
    const [short, setShort] = useState("");
    const [description, setDescription] = useState("");
    const [status_id, setStatusId] = useState(1);

    const handleUpdate = () => {
        setIsSubmetting(true);
        const formData = new FormData();
        if (image)
            formData.append('image', image);
        formData.append('title', title);
        formData.append('domaine', domaine);
        formData.append('realisation', realisation);
        formData.append('motivation', motivation);
        formData.append('short', short);
        formData.append('description', description);
        formData.append('status_id', status_id);
        formData.append('user_id', user.id);
        let url = `/projects`;
        if (project) {
            formData.append('_method', 'PUT');
            url = `/projects/${project.id}`;
        }


        axios.
            post(`${BaseUrl}${url}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token ?? ""}`
                }
            })
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        swal('Innovation', "Projet ajouter avec succcès!!", "success")
                            .then(() => {
                                window.location = '/profile'
                            });
                }
            })
            .catch(err => {
                if (err.response?.data) {
                    const { data } = err.response;
                    if (data.errors)
                        setErrors(data.errors);
                }
            })
            .finally(() => {
                setIsSubmetting(false);
            })
    }

    async function getStatuses() {
        axios.
            get(`${BaseUrl}/statuses`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setStatuses(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    function getProject() {
        axios.
            get(`${BaseUrl}/projects/${id}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data) {
                        setProject(data)
                        setTitle(data.title);
                        setDomaine(data.domaine);
                        setRealisation(data.realisation);
                        setMotivation(data.motivation);
                        setShort(data.short);
                        setStatusId(data.status_id);
                        setDescription(data.description);
                        setMotivation(data.motivation);
                        setEditorState(EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                                convertFromHTML(data.description)
                            )
                        ));
                        setEditorState2(EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                                convertFromHTML(data.motivation)
                            )
                        ));
                    }
                }
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    useEffect(() => {
        getStatuses();
        if (id) {
            getProject();
        }
    }, [])

    console.log(editorState);

    return (
        <div class="container bootstrap snippet">
            <div class="row">
                <div class="col-sm-10"><h1>{user.name}</h1></div>
                <div class="col-sm-2">
                    <a href="/users" class="pull-right">
                        <img title="profile image" class="img-circle img-responsive" src="http://www.gravatar.com/avatar/28fd20ccec6865e2d5f0e1f4446eb7bf?s=100" />
                    </a>
                </div>
            </div>
            <div class="container px-5">
                <form class="form" action="##" method="post" id="registrationForm">
                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="title"><h4>Image</h4></label>
                            <input type="file" accept="image/*" class={`form-control ${errors?.image ? 'is-invalid' : ''}`} name="image" id="image" onChange={e => setImage(e.target.files[0])} placeholder="Titre" />
                        </div>
                    </div>
                    <p>
                        {image &&
                            <img src={URL.createObjectURL(image)} style={{ maxWidth: '90%' }} alt="" />
                        }

                        {project &&
                            <img src={project.image} style={{ maxWidth: '90%' }} alt="" />
                        }
                    </p>
                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="title"><h4>Titre</h4></label>
                            <input type="text" class={`form-control ${errors?.title ? 'is-invalid' : ''}`} name="title" id="title" value={title} onChange={e => setTitle(e.target.value)} placeholder="Titre" />
                            {errors.title &&
                                <div className="invalid-feedback">
                                    {errors.title[0]}
                                </div>
                            }
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="domaine"><h4>Domaine</h4></label>
                            <input type="text" class={`form-control ${errors?.domaine ? 'is-invalid' : ''}`} name="domaine" id="domaine" value={domaine} onChange={e => setDomaine(e.target.value)} placeholder="Domaine" />
                            {errors.domaine &&
                                <div className="invalid-feedback">
                                    {errors.domaine[0]}
                                </div>
                            }
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="realisation"><h4>Réalisation en (%)</h4></label>
                            <input type="number" min={0} max={100} class={`form-control ${errors?.realisation ? 'is-invalid' : ''}`} name="realisation" id="realisation" value={realisation} onChange={e => setRealisation(e.target.value)} placeholder="Réalisation" />
                            {errors.realisation &&
                                <div className="invalid-feedback">
                                    {errors.realisation[0]}
                                </div>
                            }
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="short"><h4>Bref Description</h4></label>
                            <input type="text" class={`form-control ${errors?.short ? 'is-invalid' : ''}`} name="short" id="short" value={short} onChange={e => setShort(e.target.value)} placeholder="" />
                            {errors.short &&
                                <div className="invalid-feedback">
                                    {errors.short[0]}
                                </div>
                            }
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="short"><h4>Status</h4></label>
                            <select className={`form-control ${errors?.status_id ? 'is-invalid' : ''}`} name="status_id" id="status_id" defaultValue={short} onChange={e => setStatusId(e.target.value)}>
                                {
                                    statuses.length > 0 &&
                                    statuses.map((item, index) => (
                                        <option key={"status" + index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            {errors.status_id &&
                                <div className="invalid-feedback">
                                    {errors.status_id[0]}
                                </div>
                            }
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="motivation"><h4>Motivation</h4></label>
                            <Editor
                                editorState={editorState2}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(text) => setEditorState2(text)}
                                onChange={text => setMotivation(draftToHtml(convertToRaw(editorState2.getCurrentContent())))}
                            />
                            {/* <textarea className={`form-control summernote ${errors?.motivation ? 'is-invalid' : ''}`} name="motivation" id="motivation" defaultValue={motivation} onChange={e => setMotivation(e.target.value)} rows="5"></textarea> */}
                            {errors.motivation &&
                                <div className="invalid-feedback">
                                    {errors.motivation[0]}
                                </div>
                            }
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-xs-6">
                            <label for="description"><h4>Description</h4></label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(text) => setEditorState(text)}
                                onChange={text => setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
                            />
                            {/* <textarea className={`form-control summernote ${errors?.description ? 'is-invalid' : ''}`} name="description" id="description" defaultValue={description} onChange={e => setDescription(e.target.value)} rows="5"></textarea> */}
                            {errors.description &&
                                <div className="invalid-feedback">
                                    {errors.description[0]}
                                </div>
                            }
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <br />
                            <button disabled={isSubmitting} class="btn btn-lg btn-success pull-right" type="button" onClick={handleUpdate}><i class="glyphicon glyphicon-ok-sign"></i> Enregistrer</button>
                            <button class="btn btn-lg" type="reset" onClick={() => window.location = '/profile'}><i class="glyphicon glyphicon-repeat"></i> Annuler</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

}