import react, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import MobileNav from "./MobileNav";
import { BaseUrl, getInitials } from "../config";
import Axios from "axios";

const Navbar = () => {
    let userData = {};
    if (localStorage.getItem('user-data')) {
        if (localStorage.getItem('user-data')?.length > 0) {
            userData = JSON.parse(localStorage.getItem('user-data'));
        }
    }
    else {
        localStorage.setItem('user-data', JSON.stringify(''))
    }

    const logout = () => {
        Axios.
            post(`${BaseUrl}/user_logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${userData.token}`
                }
            })
            .finally(() => {
                localStorage.setItem('user-data', JSON.stringify({ user: null, token: null }));
                window.location.pathname = '/';
            })
    }
    return (
        <>
            <MobileNav />
            <div className="px-5 bg-primary">
                <nav className="site-nav">
                    <div className="logo">
                        <a href="/" className="text-white">
                            <img src="/logo.png" alt="logo-misn" width={100} className={"img-fluid"} />
                        </a>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-12 site-navigation text-center">
                            <ul className="js-clone-nav d-none d-lg-inline-block text-left site-menu mx-2">
                                <li className="active">
                                    <Link to={'/'}>
                                        Accueil
                                    </Link>
                                </li>
                                <li className="has-children">
                                    <Link to={'/a-propos'}>
                                        A propos
                                    </Link>
                                    <ul className="dropdown">
                                        <li><a href="/a-propos">MISN</a></li>
                                        <li><a href="/a-propos/gouvernance">Gouvernance</a></li>
                                        <li><a href="/a-propos/aide">Aide</a></li>
                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href="#">Activités</a>
                                    <ul className="dropdown">
                                        <li><a href="/formations">Formation</a></li>
                                        <li><a href="/coffees">Café de l'innovation</a></li>
                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href="/innovations">Innovations</a>
                                    <ul className="dropdown">
                                        <li><a href="/innovations/ideations">Idéations</a></li>
                                        <li><a href="/innovations/marches">En Marches</a></li>
                                        <li><a href="/innovations/mis-a-echelle">Mise à l'échelle</a></li>
                                    </ul>
                                </li>
                                <li><a href="/blog">Blog</a></li>
                                <li><a href="/contact">Nous Contacter</a></li>
                                {!userData.token && <li className="cta-button"><a href="/register">Inscription</a></li>}
                                {userData.token &&
                                    <li className="has-children">
                                        <a href="#" className="text-uppercase initials">{getInitials(userData.user?.name)}</a>
                                        <ul className="dropdown">
                                            <li><a href="/profile">Profile</a></li>
                                            <li className="cta-button"><a className="btn" onClick={logout}>Se deconnecter</a></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            {!userData.token &&
                                <ul className="js-clone-nav d-none d-lg-inline-block text-left site-menu float-right menu-absolute">
                                    <li className="cta-button mx-2"><a href="/login">Connexion</a></li>
                                </ul>
                            }
                            <a href="#" className="burger light ml-auto site-menu-toggle js-menu-toggle d-block d-lg-none" data-toggle="collapse" data-target="#main-navbar">
                                <span></span>
                            </a>

                        </div>

                    </div>
                </nav>

            </div>
            <Outlet />
            <div className="container-fluid pt-4 pb-3 bg-primary text-white">
                <div className="row footer">
                    <div className="col-12 col-md-4 d-block">
                        <h4 className="footer-logo">Maison de l'Innovation Sociale Numérique.</h4>
                        <address>
                            Immeuble Borkou, Boulevard de Paris, Rue 1008, porte 915
                        </address>
                        <a className="bottom-link" href="tel:+23566592383">+235 66 59 23 83</a> <br />
                        <a className="bottom-link" href="mailto:contact@misn-td.org">contact@misn-td.org</a>
                        <address>BP: 906 N'Djamena, Chad</address>
                    </div>
                    <div className="col-12 col-md-6 row footer-links">
                        <div className="col-12">
                            <h5 className="text-left text-md-center">Liens</h5>
                        </div>
                        <div className="col-12 col-sm">
                            <ul className="">
                                <li><Link className="bottom-link" to={'/'}>Accueil</Link> </li>
                                <li><Link className="bottom-link" to={'/a-propos'}>A propos</Link> </li>
                                <li><Link className="bottom-link" to={'/innovations'}>Innovations</Link> </li>
                                <li><Link className="bottom-link" to={'/formations'}>Formations</Link> </li>
                                <li><Link className="bottom-link" to={'/coffees'}>Café de l'innovation</Link> </li>
                                <li><Link className="bottom-link" to={'#'}>Politiques de confidentialité</Link> </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm">
                            <ul className="">
                                <li><Link className="bottom-link" to={'/blog'}>Blog</Link> </li>
                                <li><Link className="bottom-link" to={'/contact'}>Contact</Link> </li>
                                <li><Link className="bottom-link" to={'/a-propos'}>MISN</Link> </li>
                                <li><Link className="bottom-link" to={'/a-propos'}>Gouvernement</Link> </li>
                                <li><Link className="bottom-link" to={'/a-propos'}>Aide.</Link> </li>
                                <li><Link className="bottom-link" to={'#'}>Conditions d'utilisation</Link> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 col-md-2">
                        <img src="/logo.png" alt="logo-misn" className={"img-fluid"} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;