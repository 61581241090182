import Axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseUrl, slug } from "../config";

export default function Formations() {
    const [formations, setFormations] = useState([]);

    useEffect(() => {
        getDatas();
    }, [])

    async function getDatas() {
        let url = "/formations";
        Axios.
            get(`${BaseUrl}${url}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data?.length > 0)
                        setFormations(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-min.png')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Formations</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Maison de l'Innovation Sociale Numérique</p>
                            {/* <p data-aos="fade-up" data-aos-delay="200"><a href="#" className="btn btn-primary">Get Started</a></p> */}
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/reactAssets/images/slant-primary.svg')` }}></div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">Toutes les formations</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            formations?.length > 0 && formations?.map((item, i) => (
                                <div key={`formation-${i}`} className="col-md-6 col-lg-4 mb-4 mb-lg-4 mx-auto" data-aos="fade-up">
                                    <div className="unit-4">
                                        <div className="unit-4-icon mr-4" style={{ backgroundImage: `url(${(new URL(item.image)).href})`, backgroundSize: 'cover', height: 220, width: "100%" }}>
                                        </div>
                                        <div className="bg-white p-3">
                                            <h5 className="title-showmore">{item.title}</h5>
                                            <div className=''>
                                                <p className="text-showmore">
                                                    {item.short}
                                                </p>
                                            </div>
                                            <p><Link to={`/formations/${item.id}-${slug(item.title)}`} data-toggle="modal" data-target={`#about-${i}`}>Lire plus</Link></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {formations?.length === 0 &&
                            <p className="text-center">Aucun élément pour le moment.</p>
                        }
                    </div>
                </div>
            </div>

        </>
    );

}