import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseUrl, slug } from "../config";
import parse from 'html-react-parser';
import { DotLoader } from "react-spinners";

export default function Aide() {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDatas();
    }, [])

    async function getDatas() {
        let url = "/faqs";
        axios.get(`${BaseUrl}${url}`)
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setFaqs(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-2.png')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7 intro text-center">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Aide - FAQs</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">Trouver des réponses sur les questions les plus fréquemment posées</p>
                        </div>
                    </div>
                </div>
                <div className="slant" style={{ backgroundImage: `url('/reactAssets/images/slant-primary.svg')` }}></div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">Frequently Asked Questions</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        <div id="accordion">
                            {
                                faqs?.length > 0 && faqs?.map((item, i) => (
                                    <div className="card">
                                        <div className="card-header" id={`heading${i + 1}`}>
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" data-toggle="collapse" data-target={`#collapse${i + 1}`} aria-expanded="true" aria-controls={`collapse${i + 1}`}>
                                                    #{i + 1} - {item.question}
                                                </button>
                                            </h5>
                                        </div>

                                        <div id={`collapse${i + 1}`} className={`collapse ${i === 0 ? 'show' : ''}`} aria-labelledby={`heading${i + 1}`} data-parent="#accordion">
                                            <div className="card-body">
                                                {parse(item.answer)}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <p className="text-center w-100">
                            <DotLoader
                            className="mx-auto"
                                color={'#007bff'}
                                loading={loading}
                                size={35}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </p>
                        {!loading && faqs?.length === 0 &&
                            <p className="text-center w-100">Aucun élément pour le moment.</p>
                        }
                    </div>
                </div>
            </div>

        </>
    );

}