import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { BaseUrl } from "../../config";

export default function Login() {
    const [showPass, setShowPass] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [isSubmitting, setIsSubmetting] = useState(false);

    function handleLogin() {
        setIsSubmetting(true);
        if (login === "" || password === "")
            swal("Validation", 'verifier les informations saisie', 'warning')
        else {
            axios.post(`${BaseUrl}/login`, { email: login, password: password }, {
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                }
            })
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        localStorage.setItem('user-data', JSON.stringify(res.data));
                        swal('Succès', "Vous êtes connecté avec succès!", "success")
                            .then(() => {
                                setLogin('');
                                setPassword('');
                                window.location.pathname = '/'
                            })
                    }
                })
                .catch(err => {
                    console.error('on Logging', err.message);
                    if (err.response?.data) {
                        let { error } = err.response.data;
                        swal('Erreur', error, 'error')
                    }
                })
                .finally(() => setIsSubmetting(false))
        }
    }

    let userData = {};
    if (localStorage.getItem('user-data').length > 0) {
        console.log('storage', localStorage.getItem('user-data'));
        userData = JSON.parse(localStorage.getItem('user-data'));
    }

    if (userData.token && userData.user)
        window.location.pathname = '/'

    // useEffect(() => {
    // })

    return (
        <>
            <div className="site-section bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-4 bg-primary py-3 px-5 rounded">
                            <p className="text-center py-4">
                                <img src="/logo.png" alt="logo MISN" className="img-fluid" />
                            </p>

                        </div>
                        <div className="col-12 col-md-6 mx-auto border rounded mx-2 py-3">
                            <h5 className="text-center text-uppercase">Connexion</h5>
                            <form className="needs-validation w-75 mx-auto">
                                <div className="form-row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="login">Login / Email</label>
                                        <input type="text" className="form-control" id="login" placeholder="Login" value={login} onChange={e => setLogin(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="form-row align-item-center">
                                    <div className="col-md mb-3">
                                        <label htmlFor="password">Mot de passe</label>
                                        <input type={!showPass ? "password" : "text"} className="form-control" id="password" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                                        <button type="button" className="btn password-btn" onClick={() => setShowPass(!showPass)}>
                                            {showPass ?
                                                <img src="reactAssets/images/eye-slash.png" alt="" />
                                                :
                                                <img src="reactAssets/images/eye-icon.png" alt="" />
                                            }
                                        </button>
                                    </div>
                                </div>
                                <p className="text-white">
                                    <a className="" href="mailto:contact@misn-td.org">mot de passe oublié ?</a>
                                </p>
                                <button disabled={isSubmitting} className="btn btn-primary" type="button" onClick={() => handleLogin()}>Connexion</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}