import Axios from "axios";
import { useState, useEffect } from "react";
import { DotLoader } from "react-spinners";
import swal from "sweetalert";
import { BaseUrl } from "../config";

export default function Contact() {
    const [isSubmitting, setIsSubmetting] = useState(false);
    const [errors, setErrors] = useState({});
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    function resetFields() {
        setFirstname('');
        setLastname('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    useEffect(() => {
    }, [errors, lastname])

    async function handleSubmit() {
        setIsSubmetting(true)

        let body = { firstname: firstname, lastname: lastname, email: email, subject: subject, message: message };

        Axios.post(`${BaseUrl}/contact`, body, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        })
            .then(res => {
                if (res.data && res.data === "success")
                    swal('Succès', "Votre message a été envoyé avec succès!", "success")
                        .then(() => {
                            window.location.pathname = '/'
                        })
            })
            .catch(err => {
                if (err.response?.data) {
                    const { errors } = err.response.data;
                    setErrors(errors);
                }
            })
            .finally(() => setIsSubmetting(false))
    }

    return (
        <>
            <div className="site-section bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="d-none d-md-block col-12 col-md bg-primary py-3 px-5 rounded">
                            <p className="text-center py-4">
                                <img src="/logo.png" alt="logo MISN" className="img-fluid" />
                            </p>
                            <h5 className="text-white text-center text-uppercase">Contact Nous</h5>
                            <div className="text-white">
                                <address>Immeuble Borkou, Boulevard de Paris, Rue 1008, porte 915</address>
                                <a className="text-white" href="tel:+23566592383">Tel : +235 66 59 23 83</a> <br />
                                <a className="text-white" href="mailto:contact@misn-td.org">Mail : contact@misn-td.org</a>
                                <address>BP: 906 N'Djamena, Chad</address>
                            </div>
                        </div>
                        <div className="col-12 col-md border rounded mx-2 py-3">
                            <form className="needs-validation" noValidate>
                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="firstname">Pérnom</label>
                                        <input type="text" className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} id="firstname" placeholder="Prénom" value={firstname} onChange={e => setFirstname(e.target.value)} />
                                        {errors.firstname &&
                                            <div className="invalid-feedback">
                                                {errors.firstname[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastname">Nom</label>
                                        <input type="text" className={`form-control ${errors.lastname ? 'is-invalid' : ''}`} id="lastname" placeholder="Nom" value={lastname} onChange={e => setLastname(e.target.value)} required />
                                        {errors.lastname &&
                                            <div className="invalid-feedback">
                                                {errors.lastname[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                        {errors.email &&
                                            <div className="invalid-feedback">
                                                {errors.email[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="subject">Objet</label>
                                        <input type="email" className={`form-control ${errors.subject ? 'is-invalid' : ''}`} id="subject" placeholder="Objet" value={subject} onChange={e => setSubject(e.target.value)} required />
                                        {errors.subject &&
                                            <div className="invalid-feedback">
                                                {errors.subject[0]}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label htmlFor="message">Message</label>
                                        <textarea className={`form-control ${errors.message ? 'is-invalid' : ''}`} name="message" id="message" rows="5" onChange={e => setMessage(e.target.value)} value={message}></textarea>
                                        {errors.message &&
                                            <div className="invalid-feedback">
                                                {errors.message[0]}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <button disabled={isSubmitting} className="btn btn-primary" type="button" onClick={() => handleSubmit()}>
                                    Envoyer
                                    <DotLoader
                                        color={'#fff'}
                                        loading={isSubmitting}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}