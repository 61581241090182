import axios from "axios";
import { useState, useEffect } from "react";
import { BaseUrl } from "../config";
import parse from 'html-react-parser';
import { DotLoader } from "react-spinners";

export default function Gouverning() {
    const [gouverning, setGouverning] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getDatas();
    }, [])

    const getDatas = () => {
        axios.get(BaseUrl + '/abouts/1')
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setGouverning(data);
                }
            })
            .catch(err => {
                console.error('I got an error', err.message);
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            {!isLoading && gouverning &&
                <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/hero-2.png')` }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-7 intro text-center">
                                <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">A propos</h1>
                                <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">{gouverning.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="slant" style={{ backgroundImage: gouverning?.image }}></div>
                </div>
            }

            {!isLoading && gouverning &&
                <div className="site-section bg-light">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center" data-aos="fade-up">
                                <p>{gouverning?.short}</p>
                            </div>
                        </div>
                        <div className="align-items-stretch">
                            {parse(gouverning.text)}
                        </div>
                    </div>
                </div>
            }
            <p className="text-center">
                <DotLoader
                    color={'#fff'}
                    loading={isLoading}
                    size={75}
                    aria-label="Loading Spinner"
                    data-testid="loader" />
            </p>

        </>
    );

}