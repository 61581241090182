import Axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../config";
import parse from 'html-react-parser';

export default function ShowProject() {
    const { id = null } = useParams();
    const [project, setProject] = useState({});
    let imgUrl = '/reactAssets/images/hero-min.png';
    const [isLoading, setIsLoading] = useState(true);

    if (id)
        getData(id);

    async function getData(id) {
        const url = "/projects/" + id;
        Axios.
            get(`${BaseUrl}${url}`)
            .then(res => {
                console.log(res.data);
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setProject(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
            .finally(() => setIsLoading(false))
    }

    if (project?.image) imgUrl = new URL("" + project.image).href;
    return (
        <>
            {isLoading &&
                <div className="py-5 my-5 text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!isLoading &&
                <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url(${imgUrl})` }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-7 intro text-center">
                                <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Innovation</h1>
                                <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">{project.title}</p>
                            </div>
                        </div>
                    </div>
                    <div className="slant" style={{ backgroundImage: `url('/assets/images/slant.svg')` }}></div>
                </div>
            }
            {!isLoading &&
                <div className="site-section bg-light">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center" data-aos="fade-up">
                                <h2 className="heading font-weight-bold mb-3 text-capitalize">{project.title}</h2>
                            </div>
                        </div>
                        <div className="">
                            <p>{project.short}</p>
                            {project.domaine &&
                                <h5>Domaine : {project.domaine}</h5>
                            }

                            {project.motivation &&
                                <div className="w-100">
                                    <h4>Motivation</h4>
                                    <div className="w-100">
                                        {parse(project.motivation)}
                                    </div>
                                </div>
                            }
                            <div>
                                {project.description && parse(project?.description)}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

}