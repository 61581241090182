import Axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../config";
import parse from 'html-react-parser';

export default function ShowFormation() {
    const { slug = null } = useParams();
    const [formation, setFormation] = useState({});
    let imgUrl = '/reactAssets/images/hero-min.png';
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let id = parseInt(slug?.split('-')[0]);
        if (id)
            getData(id);
    }, [])

    async function getData(id) {
        let url = "/formations/" + id;
        Axios.
            get(`${BaseUrl}${url}`)
            .then(res => {
                console.log(res.data);
                if (res.data) {
                    let { data } = res.data;
                    if (data)
                        setFormation(data);
                }
            })
            .catch(err => {
                console.error(err.message);
            })
            .finally(() => setIsLoading(false))
    }

    if (formation?.image) imgUrl = new URL("" + formation.image).href;
    return (
        <>

            {isLoading &&
                <div className="py-5 my-5 text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {!isLoading &&
                <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url(${imgUrl})` }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-7 intro text-center">
                                <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Formation</h1>
                                <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">{formation.title}</p>
                            </div>
                        </div>
                    </div>
                    <div className="slant" style={{ backgroundImage: `url('/assets/images/slant.svg')` }}></div>
                </div>
            }

            {!isLoading &&
                <div className="site-section bg-light">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center" data-aos="fade-up">
                                <h2 className="heading font-weight-bold mb-3 text-capitalize">{formation.title}</h2>
                            </div>
                        </div>
                        <div className="">
                            <p>{formation.short}</p>
                            <div>
                                {formation.description && parse(formation?.description)}
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );

}