import axios from 'axios';
import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import parse from 'html-react-parser';
import swal from "sweetalert";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './index.css';
import { BaseUrl, slug } from '../config';

const owlReponsive = {
    0: {
        items: 1,
    },
    480: {
        items: 2,
    },
    768: {
        items: 3
    },
    900: {
        items: 4
    }
}

export default function Index() {
    const [partners, setParners] = useState([]);
    const [userData, setUserData] = useState(null);
    const [abouts, setAbouts] = useState([]);
    const [email, setEmail] = useState("");
    const [activities, setActivities] = useState([]);
    const [posts, setPosts] = useState([]);
    const [projects, setProjects] = useState();
    const [loadingAbout, SetloadingAbout] = useState(true);
    const [isSubscribing, setIsSubscribing] = useState(false);


    const getPartners = () => {
        axios
            .get(BaseUrl + '/partners')
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data.length > 0)
                        setParners(res.data.data);
                }
            })
            .catch(err => console.error(err.message));
    }

    const getAbouts = () => {
        axios
            .get(BaseUrl + '/events')
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data.length > 0)
                        setAbouts(data);
                }
            })
            .catch(err => console.error(err.message))
            .finally(() => SetloadingAbout(false))
    }

    const newsSubscribe = (e) => {
        e.preventDefault()
        setIsSubscribing(true);
        axios
            .post(BaseUrl + '/newsletter', { email: email })
            .then(res => {
                if (res.data && res.status === 200) {
                    let { message = "" } = res.data;
                    swal('Merci', message, 'success');
                    setEmail('')
                }
            })
            .catch(err => console.error(err.message))
            .finally(() => setIsSubscribing(false));
    }

    const getActivities = () => {
        axios
            .get(BaseUrl + '/activities')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setActivities(res.data);
                }
            })
            .catch(err => console.error(err.message))
    }

    const getPosts = () => {
        axios
            .get(BaseUrl + '/posts?per_page=3')
            .then(res => {
                if (res.data) {
                    let { data } = res.data;
                    if (data.length > 0)
                        setPosts(data);
                }
            })
            .catch(err => console.error(err.message))
    }

    const getProject = () => {
        axios
            .get(BaseUrl + '/projects/count-all')
            .then(res => {
                if (res.data !== null) {
                    setProjects(res.data)
                }
            })
            .catch(err => console.error(err.message))
    }

    useEffect(() => {
        if (localStorage.getItem('user-data')) {
            if (localStorage.getItem('user-data')?.length > 0) {
                setUserData(JSON.parse(localStorage.getItem('user-data')));
            }
        }
        getPartners();
        getAbouts();
        getActivities();
        getPosts();
        getProject();
    }, []);

    return (
        <>
            <div className="hero-slant overlay" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url('/reactAssets/images/misn.gif')` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-7 intro">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">Newsletter</h1>
                            <p className="text-white mb-4" data-aos="fade-up" data-aos-delay="100">
                                S'abonner pour être informer!
                            </p>
                            <form onSubmit={(event) => newsSubscribe(event)} className="sign-up-form d-flex" data-aos="fade-up" data-aos-delay="200">
                                <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Enter email address" />
                                <input type="submit" className="btn btn-primary" value="M'Abonner" />
                            </form>
                        </div>
                        {/* <div className="col-lg-7 intro">
                            <h1 className="text-white font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="0">{'HACKATHON'}</h1>
                            <p className="text-white text-center mb-4" data-aos="fade-up" data-aos-delay="100">
                                {'Appel à projet : "INNOVATION POUR UN DÉVELOPPEMENT DURABLE E INCLUSIF"'}
                            </p>
                            <div className="bg-white py-3 px-2 rounded d-flex align-items-center">
                                <span className='flex-grow-1'>{'Soumettre ma candidature via le '}</span>
                                <a href='https://urlz.fr/mj5h' target='_blank' className='btn btn-primary'>{'Formulaire'}</a>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className="slant" style={{ backgroundImage: `url('assets/images/slant.svg')` }}></div> */}
            </div>

            <div className="py-3">
                <div className="container">
                    {partners?.length > 0 &&
                        <OwlCarousel responsive={owlReponsive} className="owl-logos owl-theme" loop autoPlay autoplayTimeout={2000} nav>
                            {partners.map((item, index) => (
                                <div key={`partner-${index}`} className="item" data-toggle="modal" data-target={`#partner-${index}`}>
                                    {
                                        item.id === 5 ?
                                            <img src={item.logo} alt={`Logo de ${item.name}`} title={item.name} className="img-fluid" style={{ height: 150, width: '100%' }} />
                                            : <img src={item.logo} alt={`Logo de ${item.name}`} title={item.name} className="img-fluid" />
                                    }
                                </div>
                            ))
                            }
                        </OwlCarousel>
                    }

                </div>

            </div>
            {/* Partners modals to show more about them */}
            {partners?.length > 0 && partners.map((item, index) => (
                <div key={`partner-${index}`} className="modal fade" id={`partner-${index}`} tabIndex="-1" aria-labelledby={`#partner-${index}Label`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={`#partner-${index}Label`}>{`Info : ${item.name}`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center">
                                    <img src={item.logo} alt={`Logo de ${item.name}`} title={item.name} className="img-fluid mx-auto" width={'50%'} />
                                </p>
                                <div>{item.description ? parse(item.description ?? '') : item.short}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                <a target={'_blank'} href={item.link} className="btn btn-primary text-white">Lire plus</a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}


            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3 text-capitalize">actualités & évènements</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            abouts.length > 0 && abouts.map((item, i) => (
                                <div key={`about-${i}`} className="col-md-6 col-lg-3 mb-4 mb-lg-4 mx-auto" data-aos="fade-up">
                                    <div className="unit-4">
                                        <div className="unit-4-icon mr-4" style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', height: 120 }}>
                                            {/* <span className="feather-pen-tool"></span> */}
                                            {/* <img src={item.imgurl} alt="" className="img-fluid" /> */}
                                        </div>
                                        <div>
                                            <h5>{item.title}</h5>
                                            <div className='about-detail'>
                                                <div>
                                                    {parse(item.short)}
                                                </div>
                                            </div>
                                            <p><a href="#" data-toggle="modal" data-target={`#about-${i}`}>Lire plus</a></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* Abouts modals to show more about them */}
            {abouts?.length > 0 && abouts.map((item, index) => (
                <div key={`about-${index}`} className="modal fade" id={`about-${index}`} tabIndex="-1" aria-labelledby={`#about-${index}Label`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={`#about-${index}Label`}>{`Info : ${item.title}`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img src={item.image} alt={`Logo de ${item.title}`} title={item.title} className="img-fluid" width={'100%'} />
                                <div>{parse(item.description)}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                {/* <a target={'_blank'} href={item.link} className="btn btn-primary">Lire plus</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }


            <div className="site-section overlay site-cover-2" style={{ backgroundImage: `url('assets/images/hero-min.png')` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mx-auto text-center">
                            <h2 className="text-white mb-4">J'inscrit mon projet pour une meilleure visibilité! </h2>
                            <p className="mb-0"><a href={!userData ? "/register" : "/profile"} rel="noopener" className="btn btn-primary">Appliquer</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <h2 className="heading font-weight-bold mb-3">Nos Activiés</h2>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {
                            activities.length > 0 && activities.map((item, index) => (
                                <div key={'activity-index#' + index} className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                                    <div className="unit-4 d-flex">
                                        <div className="unit-4-icon mr-4">
                                            {/* <span className="feather-pen-tool"></span> */}
                                            <img src={item.icon} width={57} className="img-fluid" alt="" />
                                        </div>
                                        <div>
                                            <h3>{item.name}</h3>
                                            <div className='activity'>
                                                {item.short}
                                            </div>
                                            <p><a href="#" data-toggle="modal" data-target={`#activity-${index}`}>Voir +</a></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* Activities modals to show more about them */}
            {activities?.length > 0 && activities.map((item, index) => (
                <div key={`activity-${index}`} className="modal fade" id={`activity-${index}`} tabIndex="-1" aria-labelledby={`#activity-${index}Label`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={`#activity-${index}Label`}>{`Activité : ${item.name}`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center">
                                    <img src={item.icon} alt={`icon-${item.name}`} title={item.name} className="img-fluid" width={'30%'} />
                                </p>
                                <div>{parse(item.description)}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                {/* <a target={'_blank'} href={item.link} className="btn btn-primary">Lire plus</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
            <div className="features-lg bg-ligth">
                <div className="container">
                    <div className="row feature align-items-center justify-content-between">
                        <div className="col-lg-4 section-title" data-aos="fade-up" data-aos-delay="100">
                            <h2 className="font-weight-bold mb-4 heading">Vidéo</h2>
                            <div className="text-showmore">
                                <div>{parse('<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:12.0pt"><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">Dans le cadre du projet Maison de l&rsquo;Innovation Social Num&eacute;rique lanc&eacute; par le Programme des Nations Unie pour le D&eacute;veloppement (PNUD), Le R&eacute;seau des Jeunes pour le D&eacute;veloppement et le Leadership au Tchad en partenariat avec PNUD a engag&eacute; un programme (projet) de formation des entrepreneurs et startupers sur la th&eacute;matique &laquo; comment cr&eacute;er son site web &raquo; Car de nos jours la Nouvelle technologie de l&rsquo;Information et de la Communication (NTIC) constitue un fait ph&eacute;nom&eacute;nal et est en train de r&eacute;volutionner la technologie dans l&rsquo;&eacute;cosyst&egrave;me entrepreneurial particuli&egrave;rement dans le E-commerce.</span></span></span></span></p><p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:12.0pt"><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">C&rsquo;est dans ce souci le R&eacute;seau des Jeunes pour le D&eacute;veloppement et le Leadership a pris initiative de r&eacute;aliser le pr&eacute;sent projet de formation pour permettre aux entrepreneurs et aux startupers tchadiens d&rsquo;&ecirc;tre capable de cr&eacute;er leur site web.</span></span></span></span></p>')}</div>
                            </div>
                            <p><button className="btn btn-primary" data-toggle="modal" data-target={`#videoY`}>Lire +</button></p>

                        </div>
                        <div className="col-lg-7 section-stack order-lg-2 mb-4 mb-lg-0 position-relative" data-aos="fade-up" data-aos-delay="0">

                            <div className="image-stack">
                                <div className="image-stack__item image-stack__item--top">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ujpMeta8_84" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                                {/* <div className="image-stack__item image-stack__item--bottom">
                                        <img src="assets/images/img_h_3-min.jpg" alt="" />
                                    </div> */}
                            </div>

                        </div>
                    </div>
                </div>
                {/* Modal for more informations */}
                <div className="modal fade" id={`videoY`} tabIndex="-1" aria-labelledby={`#videoYLabel`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={`#videoYLabel`}>{`Video MISN`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>{parse('<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:12.0pt"><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">Dans le cadre du projet Maison de l&rsquo;Innovation Social Num&eacute;rique lanc&eacute; par le Programme des Nations Unie pour le D&eacute;veloppement (PNUD), Le R&eacute;seau des Jeunes pour le D&eacute;veloppement et le Leadership au Tchad en partenariat avec PNUD a engag&eacute; un programme (projet) de formation des entrepreneurs et startupers sur la th&eacute;matique &laquo; comment cr&eacute;er son site web &raquo; Car de nos jours la Nouvelle technologie de l&rsquo;Information et de la Communication (NTIC) constitue un fait ph&eacute;nom&eacute;nal et est en train de r&eacute;volutionner la technologie dans l&rsquo;&eacute;cosyst&egrave;me entrepreneurial particuli&egrave;rement dans le E-commerce.</span></span></span></span></p><p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:12.0pt"><span style="font-family:&quot;Times New Roman&quot;,&quot;serif&quot;">C&rsquo;est dans ce souci le R&eacute;seau des Jeunes pour le D&eacute;veloppement et le Leadership a pris initiative de r&eacute;aliser le pr&eacute;sent projet de formation pour permettre aux entrepreneurs et aux startupers tchadiens d&rsquo;&ecirc;tre capable de cr&eacute;er leur site web.</span></span></span></span></p>')}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                {/* <a target={'_blank'} href={item.link} className="btn btn-primary">Lire plus</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section bg-primary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 mx-auto text-center">
                            <h2 className="text-white mb-4">Chiffres Clés</h2>
                            <div className="row">
                                <div className="my-4 my-lg-0 col-12 col-md-6 col-lg-4 mx-auto keys-item">
                                    <h4 className='text-white'><i className="fas fa-hands"></i></h4>
                                    <h3 className=''>7</h3>
                                    <h2>Incubateurs</h2>
                                </div>
                                <div className="my-4 my-lg-0 col-12 col-md-6 col-lg-4 mx-auto  keys-item">
                                    <h4 className='text-white'><i className="fas fa-lightbulb"></i></h4>
                                    <h3 className=''>+{projects ?? '--'}</h3>
                                    <h2>Projets</h2>
                                </div>
                                <div className="my-4 my-lg-0 col-12 col-md-6 col-lg-4 mx-auto  keys-item">
                                    <h4 className='text-white'><i className="fas fa-hand-holding-usd"></i></h4>
                                    <h3 className=''>+90Millions</h3>
                                    <h2>Financements Octroyés</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="testimonial-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
                            <div className="testimonial--wrap">
                                <div className="">
                                    <div className="testimonial-item">
                                        {parse('<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FmisnTchad%2Fvideos%2F322683459663741%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>')}
                                    </div>
                                </div>
                                {/* <div className="custom-nav-wrap">
                                    <a href="#" className="custom-owl-prev"><span className="icon-keyboard_backspace"></span></a>
                                    <a href="#" className="custom-owl-next"><span className="icon-keyboard_backspace"></span></a>
                                </div> */}
                            </div>

                        </div>
                        <div className="col-lg-4 mb-5 section-title" data-aos="fade-up" data-aos-delay="0">
                            <h2 className="mb-4 font-weight-bold heading">Témoignages</h2>
                            {/* <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p> */}
                            {/* <p><a href="/register" className="btn btn-primary">S'inscrire</a></p> */}
                        </div>
                    </div>
                </div>
            </div>

            {posts.length > 0 &&
                <div className="site-section bg-light" id="blog-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-7 mb-4 position-relative text-center mx-auto">
                                <h2 className="font-weight-bold text-center">Blog</h2>
                                <p>Quelques articles les plus récents</p>
                            </div>
                        </div>
                        <div className="row">
                            {
                                posts.map((item, index) => (
                                    <div key={`post-${index}`} className="col-md-6 mb-5 mb-lg-0 col-lg-4">
                                        <div className="blog_entry">
                                            <a href={`/blog/${item.id}-${slug(item?.title)}`}>
                                                <img src={item.image} alt={`article ${item.title}`} className="img-fluid" />
                                            </a>
                                            <div className="p-4 bg-white">
                                                <h3><a className='title-showmore' href={`/blog/${item.id}-${slug(item?.title)}`}>{item.title}</a></h3>
                                                <p className='text-showmore'>{item.short}</p>
                                                <p className="more"><a href={`/blog/${item.id}-${slug(item?.title)}`}>lire la suite</a></p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 mx-auto">
                                <a href="/blog" className="btn btn-primary btn-block">Voir plus</a>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}
